.login-code-show-button {
    font-size: .8rem;
    position: absolute;
    top: -50px;
    right: 20px;
}

@media only screen and (min-width: 501px) {
  .login-code-show-button {
      font-size: 1rem;
      top: -70px;
  }
}